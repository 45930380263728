import "./Footer.css";
import "../App.css";

import insta from "../imgs/instagram.png";
import tube from "../imgs/youtube.png";

function Footer(props) {
  return (
    <footer>
      <div className="footerLinks">
        <a
          href="https://instagram.com/foothillsbeachvolleyball/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="social">
            <img src={insta} />
          </div>
        </a>
        {/* <a href="https://www.youtube.com/@hillsvb" target="_blank" rel="noreferrer"><div className="social"><img src={tube} /></div></a> */}
      </div>
    </footer>
  );
}

export default Footer;
