import "./Header.css";
import "../App.css";

import { SWLink } from "../modules/SWLink";
import logo from "../imgs/beachLogo-contained.png";

function Header(props) {
  return (
    <header className="Header">
      <div className="title">
        <div className="gradient">
          <h5 style={{ textAlign: "right" }}>Hills</h5>
          <img src={logo} alt="Logo" />
          <h5 style={{ textAlign: "left" }}>Beach</h5>
        </div>
      </div>
      <div className="seasonSelector">
        <div>
          <a href="http://foothillsvolleyball.com">Indoor</a>
        </div>
        <div>
          <a href="#" className="active">
            Beach
          </a>
        </div>
      </div>
      <div className="nav">
        <ul style={{ flexWrap: "wrap" }}>
          <li className={props.active === "Home" ? "active" : ""}>
            <SWLink to="/" content="Home" />
          </li>
          <li className={props.active === "Schedule" ? "active" : ""}>
            <SWLink to="/schedule" content="Schedule" />
          </li>
          <li className={props.active === "Teams" ? "active" : ""}>
            <SWLink to="/teams" content="Teams" />
          </li>
          <li className={props.active === "Coaches" ? "active" : ""}>
            <SWLink to="/coaches" content="Coaches" />
          </li>
          {/* <li className={props.active === "Store" ? "active" : ""}>
            <a href="/store">Store</a>
          </li> */}
        </ul>
      </div>
    </header>
  );
}

export default Header;
