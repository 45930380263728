import "./Schedule.css";
import "../App.css";

import { useEffect, useState } from "react";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import TeamSchedule from "./teamSchedule";

function Schedule() {
  useEffect(() => {
    handleTeamChange({ target: teams[0] });
  }, []);

  const teams = [{ label: "2024", value: "CAMUGUTS" }];

  const [team, setTeam] = useState(teams[0].value);
  const [teamInfo, setTeamInfo] = useState(null);
  const [selected, selectGame] = useState(null);

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
    setTeamInfo(null);
    selectGame(null);
    fetch(
      "https://us-central1-volley-stats.cloudfunctions.net/app/teams/" +
        event.target.value
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setTeamInfo(result);
        },
        (error) => {
          console.log("Error");
        }
      );
  };

  return (
    <div>
      <Header active={"Schedule"} />
      <div className="content">
        <div className="content-block">
          {teamInfo ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Dropdown
                label="Team:"
                options={teams}
                value={team}
                onChange={handleTeamChange}
                key={team}
              />
            </div>
          ) : null}
          <TeamSchedule
            team={teamInfo}
            selected={selected}
            selectGame={selectGame}
          />
          <h5 style={{ margin: "4px" }}>
            This page should be up to date, but always double check the{" "}
            <a
              href={
                "https://azpreps365.com/teams/volleyball-beach/9237-catalina-foothills/177914-varsity"
              }
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline" }}
            >
              AIA's official site
            </a>{" "}
            for official dates and match start times.
          </h5>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Dropdown = ({ label, value, options, onChange }) => {
  return (
    <div className="dropdown">
      <label>{label}</label>
      <div className="select">
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Schedule;
