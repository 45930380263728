import "../App.css";
import "./Teams.css";

import CaptionImage from "../modules/CaptionImage";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import team2024 from "../imgs/team/2024.jpeg";
import { useState } from "react";

function Teams() {
  const years = [{ label: "2024", value: 24 }];
  const [year, setYear] = useState(24);
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  return (
    <div>
      <Header active={"Teams"} />
      <div className="content">
        <Dropdown
          label="Year:"
          options={years}
          value={year}
          onChange={handleYearChange}
          key={year}
        />
        {year == 24 ? (
          <div>
            <div className="teamBox">
              <CaptionImage src={team2024} />
              <h3>Roster</h3>
              <h5>1 Elizabeth Jurgens, Freshman</h5>
              <h5>2 Carly Axen, Junior</h5>
              <h5>3 Madden Bowers, Freshman</h5>
              <h5>4 Caroline Bohney, Senior</h5>
              <h5>5 Lindon Bourn, Sophomore</h5>
              <h5>6 Malorie Tarver, Junior</h5>
              <h5>7 Rachel Landau, Junior</h5>
              <h5>8 Alexa Mayorquin, Freshman</h5>
              <h5>9 Zoe Brown, Freshman</h5>
              <h5>11 Leah Stoneking, Sophomore</h5>
              <h5>12 Olivia Oakes, Freshman</h5>
              <h5>13 Presley Jacome, Freshman</h5>
              <h5>15 Julia Stingelin, Freshman</h5>
              <h5>16 Caileen Feeney, Junior</h5>
              <h5>18 Ava Harlow, Freshman</h5>
              <h5>19 Ava Terpning, Sophomore</h5>
              <h5>20 Margaret Heslin, Freshman</h5>
              <h5>25 Laila Adcock, Junior</h5>
              <h5>32 Jordan Demont, Sophomore</h5>
              <h5>37 Penelope Reno, Freshman</h5>
            </div>
          </div>
        ) : (
          "Not available"
        )}
      </div>
      <Footer />
    </div>
  );
}

const Dropdown = ({ label, value, options, onChange }) => {
  return (
    <div className="dropdown">
      <label>{label}</label>
      <div className="select">
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Teams;
