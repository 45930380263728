import "./Home.css";
import "../App.css";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { SWLink } from "../modules/SWLink";
import homeBG from "../imgs/homebg.jpeg";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();
  useEffect(() => {
    if (window.beholdWidgets !== undefined) {
      window.beholdWidgets.initialize();
    }
  }, [location]);
  return (
    <div className="App">
      <Header active={"Home"} />
      <div className="content">
        <div className="card hero">
          <div className="titleArea">
            <h3>We're Ready!</h3>
          </div>
          <div className="bottomText">
            <div>
              <p>
                <h4>First ever beach season!</h4>
              </p>
              <p>
                Our <SWLink to="/teams" content="roster" /> has been posted and
                our <SWLink to="/schedule" content="schedule" /> is set! We're
                ready for a great year!
              </p>
            </div>
          </div>
        </div>
        {/* <div className="card logobg">
          <div className="titleArea">
            <h3>Donations</h3>
          </div>
          <div className="bottomText">
            <div>
              <p>
                <strong>
                  Your donations help our athletes succeed! Please consider
                  helping us by donating to our booster club directly or by
                  purchasing one of these items outright:
                </strong>
              </p>
              <ol>
                {/* <li>
                  <a
                    href="https://a.co/d/cum48D9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Beach Volleyballs ($37)
                  </a>
                </li> 

                <li>
                  <a
                    href="https://a.co/d/8WA52dJ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Outdoor Speaker ($137)
                  </a>
                </li>
                <li>
                  <a
                    href="https://a.co/d/g9fkSHT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    2 Scoreboards ($33 each)
                  </a>
                </li>
                <li>
                  <a
                    href="https://a.co/d/aXhsHXJ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tall Training Cones ($22)
                  </a>
                </li>
                <li>
                  <a
                    href="https://a.co/d/8jMFjg1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Disc Training Cones ($22)
                  </a>
                </li>
                <li>
                  <del>Beach Volleyball Cart</del>{" "}
                  <strong>Donated by the Stoneking Family</strong>
                </li>
                <li>
                  <del>Beach Volleyballs</del>{" "}
                  <strong>Donated by the Bourn Family</strong>
                </li>
                <li>
                  <del>Beach Volleyball Cart</del>{" "}
                  <strong>Donated by the Bourn Family</strong>
                </li>
                <li>
                  <del>Popup Shade</del>{" "}
                  <strong>Donated by the Bourn Family</strong>
                </li>
              </ol>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
