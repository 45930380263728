import "./Coaches.css";
import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import CaptionImage from "../modules/CaptionImage";
import { Carousel } from "react-responsive-carousel";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import brian1 from "../imgs/brian1.jpg";
import brian2 from "../imgs/brian2.jpg";
import sam1 from "../imgs/sam1.png";
import sam2 from "../imgs/sam2.png";
import { useState } from "react";

const CoachView = ({
  name,
  title,
  imageOne,
  paragraphOne,
  imageTwo,
  paragraphTwo,
}) => {
  return (
    <div className="coachView">
      <h2>{name}</h2>
      <h4>{title}</h4>
      {imageOne ? (
        <CaptionImage src={imageOne.src} caption={imageOne.caption} />
      ) : null}
      {paragraphOne ? (
        <p dangerouslySetInnerHTML={{ __html: paragraphOne }} />
      ) : null}
      {imageTwo ? (
        <CaptionImage src={imageTwo.src} caption={imageTwo.caption} />
      ) : null}
      {paragraphTwo ? (
        <p dangerouslySetInnerHTML={{ __html: paragraphTwo }} />
      ) : null}
    </div>
  );
};

const CoachButton = ({ index, coach, slide, setSlide }) => {
  return (
    <button
      class="coachButton"
      onClick={() => setSlide(index)}
      style={
        slide == index ? { color: "white", backgroundColor: "#232e63" } : {}
      }
    >
      <p>{coach.name.split(" ")[0]}</p>
      {coach.title}
    </button>
  );
};

function Coaches() {
  const [slide, setSlide] = useState(0);

  const heads = [
    {
      name: "Brian Bowers",
      title: "Head Coach",
      imageOne: {
        src: brian1,
        caption: "Coach Brian with Club Cactus",
      },
      paragraphOne: `Coach Brian is the first ever Foothills Beach coach!  He started his coaching
         career at Club Cactus in 2000 and served as the boy's head varsity coach at
         Tucson High for many years.  Most recently, Brian was a 12u coach at
         Club Cactus for his youngest daughter's team.  Brian's middle daughter currently plays
         for the <a href="http://foothillsvolleyball.com">indoor team</a>.  Brian also has an
         8 year old son and is helps his step-son <a href="http://foothillsvolleyball.com/coaches">Coach Andrew</a> with the
         indoor team in the fall.`,
      imageTwo: {
        src: brian2,
        caption: "Coach Brian shows off his national championship ring",
      },
      paragraphTwo: `Coach Brian played 1 year of collegiate club at ASU, followed by 4 years
        of D1 volleyball at Rutgers, and then capped off his playing career with
        2 more years of club vollebyall at U of A where he won
        <a href="https://wc.arizona.edu/papers/92/150/40_1_m.html">back to back national
        championships.</a>`,
    },
  ];
  const assistants = [
    {
      name: "Sam Gillman",
      title: "Assistant Coach",
      imageOne: {
        src: sam1,
        caption: "Sam Gillman at the University of Arizona",
      },
      paragraphOne:
        "Coach Sam Gillman is a recent graduate from the University of Arizona. At the U of A, Sam played 4 years of Collegiate Club Volleyball as a Libero. During his 4 years, he led his team to a National Championship in Men’s Division III his Senior year. Sam also won the U of A 2022 Outstanding Sportsperson of the Year Award his Senior year for his loyalty, dedication, and hard work to the U of A Men’s Club Volleyball Team. ",

      imageTwo: {
        src: sam2,
        caption: "2022 NCVF National Champions",
      },
      paragraphTwo:
        "Before starting college at Arizona, Sam attended and played volleyball for New Trier Township High School in Illinois. Sam also played for Vortex Volleyball Club, where he played 4 years as an Outside Hitter and 2 years as a Libero. ",
    },
  ];
  return (
    <div>
      <Header active={"Coaches"} />
      <div className="content">
        <div
          class="buttonGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {/* <h5>Head Coaches</h5> */}
          <div>
            {heads
              .filter((a) => !a.inactive)
              .map((coach, index) => {
                return (
                  <CoachButton
                    key={index}
                    index={index}
                    coach={coach}
                    slide={slide}
                    setSlide={setSlide}
                  />
                );
              })}
          </div>
          {/* <h5>Assistant Coaches</h5> */}
          <div>
            {assistants
              .filter((a) => !a.inactive)
              .map((coach, index) => {
                return (
                  <CoachButton
                    key={index}
                    index={index + heads.filter((a) => !a.inactive).length}
                    coach={coach}
                    slide={slide}
                    setSlide={setSlide}
                  />
                );
              })}
          </div>
        </div>
        <div style={{ width: "95vw", maxWidth: "60ch", margin: "auto" }}>
          <Carousel
            showThumbs={false}
            showArrows={false}
            showIndicators={false}
            infiniteLoop={true}
            selectedItem={slide}
            swipeScrollTolerance={50}
            preventMovementUntilSwipeScrollTolerance={true}
            onChange={(result) => {
              if (result != slide) {
                setSlide(result);
              }
            }}
          >
            {heads
              .filter((a) => !a.inactive)
              .map((coach, index) => {
                return (
                  <CoachView
                    key={index}
                    index={index}
                    name={coach.name}
                    imageOne={coach.imageOne}
                    paragraphOne={coach.paragraphOne}
                    imageTwo={coach.imageTwo}
                    paragraphTwo={coach.paragraphTwo}
                  />
                );
              })}
            {assistants
              .filter((a) => !a.inactive)
              .map((coach, index) => {
                return (
                  <CoachView
                    key={index}
                    index={index}
                    name={coach.name}
                    imageOne={coach.imageOne}
                    paragraphOne={coach.paragraphOne}
                    imageTwo={coach.imageTwo}
                    paragraphTwo={coach.paragraphTwo}
                  />
                );
              })}
          </Carousel>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Coaches;
